.delete-bg { background: $modal-cover-background; position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 9; pointer-events: none; opacity: 0; transition: opacity .15s ease;
  &.active { opacity: 1; pointer-events: initial; }
}
.delete-options { position: fixed; left: 0; right: 0; bottom: -40rem; background: $modal-background; box-shadow: 0 -1.5rem 2rem rgba(0, 0, 0, 0.15); z-index: 10; padding: 3rem 2rem; transition: bottom .15s ease;
  &.active { bottom: 0; }
}
.delete-option { padding: 1rem 2rem 1rem 5rem; margin: 0 0 1rem; position: relative; font-size: 1.4rem; line-height: 1.4; display: block; text-align: left;
  &__icon { width: 1.8rem; height: 1.8rem; position: absolute; left: 2rem; top: .8rem;
    &--cancel { top: 1rem; }
  }
}