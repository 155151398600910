@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}