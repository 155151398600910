body { background: #7A629C; font-family: $regular-font; font-size: 1.6rem; color: $default_primary; 

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

}

body {
padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
* { overscroll-behavior: none; }

.app { background: #fff; position: fixed; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; }
.survey-background:has(.online-survey-holder) { padding: 0;min-height: 100vh; }
.online-survey-holder { background: #7a629c; padding: 1rem; min-height: 100vh; display: flex; align-items: center; justify-content: center;
  @media only screen and (min-width: 601px) and (min-height: 670px) {
    padding: 2rem;
  }
}
.online-app { background: #fff; max-width: 60rem; box-shadow: 0 0 2rem rgba(0,0,0,.2); border-radius: 1rem; width: 100%; min-height: calc(100vh - 4rem); padding: 0; display: flex; flex-direction: column; position: relative; 
  @media only screen and (min-width: 601px) and (min-height: 720px) {
    //max-height: 70rem;
  }
}

@media only screen and (min-width: 601px) and (min-height: 670px) {
  body { background: #7a629c; }
  .app { width: 60rem; height: 67rem; box-shadow: 0 0 2rem rgba(0,0,0,.2); border-radius: 1rem; }
}

.survey-background { background: #fff; }
.app.online-survey-holder { position: static; transform: none; height: auto; margin: 0 auto; width: 100%; max-width: 60rem; padding: 4rem 2rem; }
.online-survey {  color: #212121; text-align: center;
  p { word-wrap: break-word; text-align: center; }
}
.online-survey-logo { margin: 0 auto; display: block; max-width: 20rem; height: auto; }
.online-survey-title { font-size: 2.4rem; font-family: poppinssemibold,sans-serif; text-align: center; line-height: 1.4; color: #6f4e85; }
.online-survey-duration { padding: 2rem; background: #ededed; margin: 0 auto 5rem;
  p { margin: 0; }
}
.online-survey__button { width: 100%; max-width: 20rem; text-align: center;}

@media only screen and (min-width: 601px) and (min-height: 670px) {
  .survey-background { padding: 4rem 0; background: #7a629c; 
    #survey-annual-completed, .background-question { background: none; }
  }
  .app.online-survey-holder { padding: 4rem; }
}

.app-promotion { background: #f5f5f5; margin: 0 -2rem; padding: 2rem; color: #212121; }
.app-promotion-title { font-size: 1.8rem; }

@media only screen and (min-width: 601px) and (min-height: 670px) {
  .app-promotion { padding: 4rem; margin: 0 -4rem -8rem; }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   body { display: block !important; }
   .app { top: 3rem; transform: translateX(-50%);}
   .app.online-survey-holder { position: absolute !important; transform: translateX(-50%) !important;  }
   .online-survey { margin: 0; }
   .online-survey img { width: 100%; }
}