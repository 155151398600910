.month-nav { display: flex; justify-content: space-between; align-items: center; padding: 1.3rem 4rem .7rem; position: sticky; background: #fff; transform: translateY(-10rem); transition: transform .25s ease .25s; z-index: 5; margin: 0 -4rem; box-shadow: 0 .2rem .2rem rgba(0,0,0,.1); top: 5rem;
  &__link { height: 4rem; width: 4rem; display: flex; align-items: center; justify-content: center;
    svg { width: .7rem; height: 1.4rem; }
  }
  &__current-month { font-size: 2rem; font-family: $semibold-font; }
  .screen--current & { transform: translateY(0); }
}

@media only screen and (min-width: 601px) and (min-height: 670px) {
.month-nav { }

}