.complete { text-align: center; position: absolute; left: 0; top: 0; height: 100%; overflow-x: hidden; overflow-y: auto; width: 100%; transition: left .3s ease; background: #F1EEF3; scroll-behavior: smooth; -webkit-overflow-scrolling: touch; }

.spinning-rays { position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 0; display: xnone; z-index: 0;
  img { position: absolute; max-width: none;  width: 75rem; height: auto; transform-origin: center center; left: 50%; top: 0; transform: translateX(-50%) rotate(0deg);  }
}
.spinning-rays__ray1 { animation: spin 20s infinite linear; opacity: .6; }
.spinning-rays__ray2 { animation: spin 5s infinite linear; opacity: .4; }
.spinning-rays__ray3 { animation: spin 15s infinite linear reverse; opacity: .2; }


@keyframes spin {
	from { transform: translateX(-50%) rotate(0deg);}
	to { transform: translateX(-50%) rotate(360deg); }
}

@keyframes bounceIn {
	0% { transform: translateX(-50%) scale(.5,.5);}
  70% { transform: translateX(-50%) scale(1.1,1.1) }
  100% { transform: translateX(-50%) scale(1,1) }
}

@keyframes titleIn {
	0% { transform: translate(-50%, 5rem) scale(.8,.8); opacity: 0;}
  100% { transform: translate(-50%, 0) scale(1,1); opacity: 1; }
}

@keyframes fadeInAndSlideUp {
	0% { opacity: 0; transform: translateY(3rem); }
  100% { opacity: 1; transform: translateY(0rem); }
}

.complete-character__title { animation: titleIn .3s ease; transform-origin: center center; }
.complete-character__character { position: absolute; z-index: 10; bottom: 0; left: 50%; transform: translateX(-50%); animation: bounceIn .3s ease; transform-origin: center center; display: block; }
.complete-character__button { opacity: 0; animation: fadeInAndSlideUp .6s ease .3s forwards; transform: translateY(3rem); position: relative; z-index: 2; }

.you-feel { font-size: 1.6rem;
  img { display: block; width: 6rem; height: 6rem; margin: .5rem auto; }
  span { display: block; font-family: $semibold-font; }
}

.review-question { padding: 1rem 1rem 0;
  p { font-size: 1.6rem; font-family: $semibold-font; }
}
.review-words { padding: 1rem 0 0; }

.review-question-heading { font-size: 1.6rem; font-family: $regular-font; margin: 0 0 1rem; }

.review-word { display: flex; align-items: center; padding: 0 4rem; margin: 0 0 2rem;
  &__word { width: 50%; text-align: right; padding: 0 1rem 0 0 ; font-family: $semibold-font; }
  &__chart { width: 50%; height: .5rem; border-radius: 1rem; background: rgba(0,0,0,.05); }
  &__bar { width: 0%; height: .5rem; border-radius: 1rem; background: $default_primary; }
}