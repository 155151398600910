.diary-entries { background: #E7E1EB; margin: 6rem -4rem 0; padding: 5rem 4rem 2.5rem; text-align: center;
  &__title { font-size: 1.8rem; font-family: $semibold-font; margin: 0 0 4rem; }
}

.diary-entry { position: relative; margin: 0 0 2rem; padding: 0; text-align: left; align-items: center; width: 100%; display: block;

  &::after { content: ''; display: block; bottom: 0; left: 0; right: 0; height: .1rem; background: $default_primary; opacity: .3; }
  
  &__details { position: relative; flex-grow: 1; padding: 0 8rem 0 4.2rem; margin: 0 0 2rem; }
  &__date { color: #333; font-family: $regular-font; font-size: 1.2rem; }
  &__category { font-family: $semibold-font; font-size: 1.6rem; }
  &__icon { position: absolute; width: 3.2rem; height: 3.2rem; left: 0; top: 0; }

  &__impact { width: 6rem; height: 1rem; background: #fff; border-radius: .3rem; position: absolute; right: 0; top: 1.5rem; }
  &__impact-bar { height: 1rem; background: $default_button; border-radius: .3rem; width: 50%; position: absolute; left: 0; top: 0; }

}

.diary-header-row { padding: 0 0 1rem; position: relative; display: flex; align-items: center; text-align: left; margin: 0 0 3rem;
  &::after { content: ''; display: block; bottom: 0; left: 0; right: 0; height: .1rem; background: $default_primary; opacity: .3; position: absolute; }
  p { font-size: 1rem; font-family: $semibold-font; margin: 0; text-transform: uppercase; letter-spacing: .1rem; 
    
    &:first-child { flex-grow: 1; }
    &:last-child { width: 6rem; }
  }

}