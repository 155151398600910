.modal-trophy { overflow: hidden;
h2,p { position: relative; z-index: 10; }
}
.trophy-won { width: 4rem; height: 4rem; position: relative; z-index: 3; fill: #fff; }

.trophy-cabinet { height: 9rem; position: relative; }



.sun { position: absolute; top:-5.5rem;	left:0;	right:0; bottom:0; margin: auto; width:8rem; height:8rem;	border-radius:50%; background:rgba(254,195,39,1);	opacity:0.9;box-shadow: 0 0 4rem 2rem rgba(254,195,39,1); }

.ray_box { position: absolute; margin: auto; top: 0; left: 0; right: 0;	bottom: 0; width:7rem; animation: ray_anim 30s linear infinite; }
.ray { background: linear-gradient(to bottom, rgba(254,195,39,0) 0%, rgba(254,195,39,0.8) 50%, rgba(254,195,39,0) 100%); margin-left:1rem; border-radius:80% 80% 0 0;    position:absolute; opacity:0.2; }

.ray1 { height:17rem; width:3rem; transform: rotate(180deg); top:-17.5rem; left: 1.5rem; }
.ray2 { height:10rem; width: .8rem; transform: rotate(220deg); top: -9rem; left: 7.5rem; }
.ray3 { height:17rem; width:5rem; transform: rotate(250deg); top:-8rem; left: 10rem; }
.ray4 { height:12rem; width:1.4rem; transform: rotate(305deg); top:3rem; left: 10rem; }
.ray5 { height:14rem; width:3rem; transform: rotate(-15deg); top: 6rem; left: 4rem; }
.ray6 { height:9rem; width:5rem; transform: rotate(30deg); top:6rem; left: -4rem; }
.ray7 { height:18rem; width:1rem; transform: rotate(70deg); top:-3.5rem; left: -4rem; }
.ray8 { height:12rem; width:3rem; transform: rotate(100deg); top:-4.5rem; left:-9rem; }
.ray9 { height:8rem; width:1rem; transform: rotate(120deg); top:-6.5rem; left:-6rem; }
.ray10 { height:19rem; width:2.3rem; transform: rotate(150deg); top:-18.5rem; left: -6rem; }

@keyframes ray_anim { 
		0% { transform: rotate(0deg);}    
    100% { transform: rotate(360deg);}
}