.support-sites { margin: 0 -4rem -4rem; }

.support-site { border-top: .1rem solid #ededed; padding: 4rem 4rem 2rem; 

  img { display: block; max-width: 28rem; width: 100%; margin: 0 auto; }
}

.support-site-description {  -webkit-touch-callout: initial; /* iOS Safari */
    -webkit-user-select: all; /* Safari */
     -khtml-user-select: all; /* Konqueror HTML */
       -moz-user-select: all; /* Old versions of Firefox */
        -ms-user-select: all; /* Internet Explorer/Edge */
            user-select: all; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

@media only screen and (min-width: 601px) and (min-height: 670px) {
  .support-sites { margin: 0 -4rem -4rem; display: flex; flex-wrap: wrap; }
  .support-site { width: 50%;
    &:nth-child(even) { border-left: .1rem solid #ededed; }
  }
}