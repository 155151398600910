.background-general { background-image: url(../images/general-bg.png); background-size: 60rem 67rem; }
.background-achievements { background-image: url(../images/background-achievements.png); background-size: 60rem 67rem; }
.background-diary-empty { background-image: url(../images/background-diary-empty.png); background-size: 60rem 67rem; }
.background-diary {  }
.background-diary-entry { background-image: url(../images/background-diary-entry.png); background-size: 60rem 67rem; }

.background-question,
#survey-annual-completed { background-color: #dee8f2; background-image: url(../images/background-question.png); background-size: 60rem 67rem; background-position: center bottom; }
.background-word { background-color: #F1EEF3; background-image: url(../images/background-word.png); background-size: 60rem 67rem; }
.background-diary-options { background-image: url(../images/background-diary-options.png); background-size: 60rem 67rem; }
.background-form { background-color: #dee8f2; background-image: url(../images/background-form.png); background-position: center bottom; background-size: 60rem 67rem; }
.background-review { background-color: #f1eef3; /* background-image: url(../images/background-review.png); background-size: 60rem 67rem; */ }
.background-review-lower { background-color: #f1eef3; /* background-image: url(../images/background-review-lower.png); background-size: 60rem 67rem; */ }
.background-today { background-color: #6f4e85; background-image: url(../images/background-today.png); background-size: 60rem 67rem; }

.parallax-scroll { background-attachment: local; }
.image-as-background { position: absolute; left: 0; top: 0; width: 60rem; max-width: none; height: 67rem; object-fit: cover; z-index: -1; }