.signpost { background: #fff; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15); border-radius: 1rem; height: 28vh; position: relative; max-width: 33.5rem; margin: 0 auto; min-height: 18rem; 
  &__title { font-size: 2rem; font-family: $semibold-font; line-height: 1.2; width: 50%; position: absolute; top: 50%; transform: translateY(-50%); z-index: 3; }
  &__blob { position: absolute; top: 45%; transform: translateY(-50%); }
  &__character { position: absolute; top: 50%; transform: translateY(-50%); }
}
.signpost__blob--mood-entry { right: 0; width: 50%; }
.signpost__blob--diary-entry { left: 0; }
.signpost__character--mood-entry { right: 2rem; }
.signpost__character--diary-entry { left: 2rem; }

.signpost--mood-entry { background: #fff url(../images/today-mood-bg.svg) no-repeat center center; background-size: cover; 
  .signpost__title { left: 2.5rem; }
}

.signpost--with-mood-entries {
  .signpost__title { top: 3.3rem; transform: none; }
  .signpost__blob--mood-entry { width: auto; }
}

.signpost--diary-entry { background: #fff url(../images/today-diary-bg.svg) no-repeat center center; background-size: cover;
  .signpost__title { top: 50%; left: 50%; transform: translateY(-50%); }
}

.streak { display: block; margin: 0; width: 6rem; height: 6rem; position: absolute; top: -2rem; right: -1rem; z-index: 2;
  img { width: 100%; height: 100%; }
  span { position: absolute; left: 50%; top: 1.2rem; color: #fff; font-size: 3.5rem; transform: translateX(-50%); font-family: $bold-font; text-shadow: -.2rem -.2rem 0 rgba(0,0,0,.2); line-height: 1.1; }
}
.diary-number-of-entries__streak { top: 3rem; left: 50%; transform: translateX(10rem); }

.signpost__most-recent-moods { position: absolute; left: 3rem; bottom: 1.7rem; z-index: 2;
  h4 { font-family: $semibold-font; font-size: 1rem; margin: 0 0 .3rem; }
}
.signpost__most-recent-mood-list { 
  img { width: 2.2rem; height: 2.2rem; margin: 0 .2rem 0 0; transform: translateY(1.5rem) scale(.5,.5); opacity: 0; transition: all .25s ease; 
    &:nth-child(1) { transition-delay: 0s; }
    &:nth-child(2) { transition-delay: .1s; }
    &:nth-child(3) { transition-delay: .2s; }
    &:nth-child(4) { transition-delay: .3s; }
    &:nth-child(5) { transition-delay: .4s; }
    &:nth-child(6) { transition-delay: .5s; }
    &:nth-child(7) { transition-delay: .6s; }
    &:nth-child(8) { transition-delay: .7s; }
    &:nth-child(9) { transition-delay: .8s; }
    &:nth-child(10) { transition-delay: .9s; }
  }
}
.screen--current {
  .signpost__most-recent-mood-list { 
    img { transform: translateX(0) scale(1,1); opacity: 1; 
      &:nth-child(1) { transition-delay: 1s; }
      &:nth-child(2) { transition-delay: 1.1s; }
      &:nth-child(3) { transition-delay: 1.2s; }
      &:nth-child(4) { transition-delay: 1.3s; }
      &:nth-child(5) { transition-delay: 1.4s; }
      &:nth-child(6) { transition-delay: 1.5s; }
      &:nth-child(7) { transition-delay: 1.6s; }
      &:nth-child(8) { transition-delay: 1.7s; }
      &:nth-child(9) { transition-delay: 1.8s; }
      &:nth-child(10) { transition-delay: 1.9s; }
    }
  }
}

@media only screen and (max-width: 364px) {
  .signpost__character--mood-entry { right: 1rem; }
  .signpost__character--diary-entry { left: 1rem; }
}

@media only screen and (min-width: 400px) {
  .signpost--diary-entry { margin-bottom: 2rem; }
}

@media only screen and (min-height: 670px) and (min-width: 601px) {
  .signpost { height: 20rem; }
}

.animate-in { transform: translateY(10rem); opacity: 0; transition: all .8s ease .25s; 
  .screen--current & { transform: translateY(0); opacity: 1; }
  &--2 { transition-delay: .35s; }
  &--3 { transition-delay: .45s; }
}