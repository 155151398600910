.form-field { margin: 0 0 1rem; }

label { font-family: $semibold-font; margin: 0 0 .3rem; }
fieldset { border: 0; padding: 0; margin: 0; min-width: 0; }
textarea { height: 22vh; font-size: 1.6rem; line-height: 1.5; color: $default_primary; }

.standard-input { background: $form-input-background; border: .1rem solid #ccc; padding: 1.3rem 1.2rem 1.1rem; width: 100%; border-radius: $form-input-border-radius; font-size: 1.8rem; font-family: $regular-font; transition: all .25s ease; box-shadow: none; -webkit-appearance: none;
  &:focus { background: $form-input-background-focus; outline: none; border: .1rem solid $default_primary; }

  &--time { width: 12rem; text-align: center; }
}

.button-group { display: flex; justify-content: space-between; padding: 1rem 0 0;
  .button--text-only { margin-left: -2.8rem; }
  .button:first-child:last-child { margin: 0 auto; }
}

.account-form-scroll-area { height: calc(100vh - 10.5rem); overflow: auto; -webkit-overflow-scrolling: touch; padding: 0 4rem 4rem; margin: 0 -4rem; }

.checkbox { position: relative; margin: 0 0 3rem; }
.checkbox__checkbox { position: absolute; opacity: 0; }
.checkbox__label { font-family: $regular-font; color: $text-colour; font-size: 1.4rem; padding: 0 0 0 4.5rem; display: block;
  &::before { display: block; content: ''; position: absolute; width: 3rem; height: 3rem; border: .1rem solid #ccc; left: 0; top: 0; border-radius: .2rem; }
}
.checkbox__checkbox:checked {
  + .checkbox__label { 
    &::after { display: block; content: ''; position: absolute; width: 3rem; height: 3rem; background: #0AC267 url(../images/check.svg) no-repeat center center; left: 0; top: 0; color: #fff; border-radius: .2rem; }
  }  
}

.pdf-download { background: #f5f5f5; height: 8rem; display: flex; align-items: center; padding: 0 2rem; margin: 0 0 3rem; color: #333; font-size: 1.4rem; line-height: 1.2; 
  svg { margin-right: 1rem; }
}


.select-css {
	display: inline-block; font-size: 1.8rem; font-family: $regular-font; color: inherit; padding: 1.3rem 2.4rem 1.1rem 1.2rem; width: auto;	max-width: 100%; margin: 0;border: .1rem solid #ccc;	border-radius: $form-input-border-radius;	-moz-appearance: none;-webkit-appearance: none;	appearance: none;	background-color: $form-input-background;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: right .7rem top 50%;
	background-size: .65rem auto;
}
.select-css::-ms-expand { display: none; }
.select-css:focus { background-color: $form-input-background-focus;	outline: none; border: .1rem solid $default_primary; }
.select-css option { font-weight:normal; }