.moods-bar-chart { display: flex; justify-content: center; margin: 0 0 $review-page-margin; 
  &--small { margin: 0 -.75rem; }
}

.moods-bar { max-width: 5rem; flex: 1; position: relative; height: 16rem; margin: 0 .75rem;
  .moods-bar-chart--small & { height: 14rem; }
}
.moods-bar__bar-holder { position: absolute; left: 50%; bottom: 6rem; height: 10rem; background: rgba(0,0,0,.05); width: 1.5rem; transform: translateX(-50%); overflow: hidden; border-radius: .3rem;
  .moods-bar-chart--small & { bottom: 4rem; margin: 0; }
  &__bar { position: absolute; left: 0; bottom: 0; height: 0%; width: 100%; border-radius: .3rem; transition: height .5s ease;
    .moods-bar--very-unhappy & { background: $default_very-unhappy; transition-delay: 0s; }
    .moods-bar--unhappy & { background: $default_unhappy; transition-delay: 0.1s; }
    .moods-bar--ok & { background: $default_ok; transition-delay: 0.2s; }
    .moods-bar--happy & { background: $default_happy; transition-delay: 0.3s; }
    .moods-bar--very-happy & { background: $default_very-happy; transition-delay: 0.4s; }
  }
}
.moods-bar__mood { width: 5rem; height: 5rem; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); 
  .moods-bar-chart--small & { width: 3rem; height: 3rem; }
}

