.screen-splash { padding: 4rem; display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center;
  &__logo { margin: 0 0 4rem; }
  &__title { margin: 0 auto 4.5rem; max-width: 38rem; }
  &__character { flex-grow: 1; }
}

@media only screen and (max-height: 680px) {
  .screen-splash {
    &__logo { margin: 0 0 2rem; }
    &__title { margin: 0 auto 2.5rem; max-width: 34rem; }
    &__character { 
      .character__character { height: 18rem; }
     }
  }
}

@media only screen and (min-height: 780px) and (max-height: 820px) {
    .screen-splash { padding-top: 10vh; }
}