.button { display: inline-flex; height: 5.2rem; border-radius: 2.6rem; line-height: 1; font-size: 1.4rem; font-family: $semibold-font; letter-spacing: .2rem; background: $default_button; color: #fff; text-transform: uppercase; border: .1rem solid $default_button; padding: 0 3rem; align-items: center; justify-content: center; flex-shrink: 0;
  &:hover { background: darken($default_button, 10%); }
}

.button--block { width: 100%; text-align: center; margin: 0 auto; padding: 0;
  + .button--block { margin-top: 2.5rem; }
}
@media only screen and (min-width: 400px) {
  .button--block { max-width: 34rem; }
}

.button--ghost { background: transparent; border-color: $default_primary; color: $default_primary; 
  &:hover { background: $default_primary; color: #fff; }
}

.button--text-only { border: none; background: none; color: $default_primary; }
.button--white-text { color: #fff; }

.icon-button { height: 4rem; width: 4rem; display: flex; align-items: center; justify-content: center; }
.back-button { position: absolute; left: 0; top: .5rem; z-index: 3; }
.back-button__icon { width: .9rem; height: 1.8rem; }

.close-button { position: absolute; right: 0; top: 0; }
.close-button__icon { width: 3.2rem; height: 3.2rem; }
.close-button--modal { right: 0; top: .4rem; z-index: 3; }

.bin-button { position: absolute; right: 0; top: 0; }
.bin-button__icon { width: 1.8rem; height: 1.8rem; }
.bin-button--modal { right: 0; top: .4rem; z-index: 3; }

.skip-button { font-size: 1.8rem; font-family: $semibold-font; margin: 1.5rem auto 0; width: 15rem; padding: .5rem;
  svg { height: 1rem; width: .5rem; 
    &:first-child { margin: 0 0 0 .3rem; }
  }
}

.finish-button { font-size: 1.8rem; font-family: $semibold-font; margin: 3rem auto 0; padding: 1rem 1rem .8rem; border: .1rem solid $default_primary; border-radius: 2.6rem; width: 15rem;
  svg { height: 1rem; width: .5rem; 
    &:first-child { margin: 0 0 0 .3rem; }
  }
}

@media only screen and (max-height: 640px) {
  .skip-button { margin-top: .5rem; }
.finish-button { margin-top: 2rem; }
}
@media only screen and (min-height: 800px) {
.skip-button { margin-top: 5rem; }
.finish-button { margin-top: 5rem; }
}

.characters-remaining { font-size: 1.2rem; text-align: right; }