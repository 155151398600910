.margin-b-0 { margin-bottom: 0; }
.margin-b-20 { margin-bottom: 2rem; }
.margin-b-25 { margin-bottom: 2.5rem; }
.margin-b-30 { margin-bottom: 3rem; }
.margin-b-35 { margin-bottom: 3.5rem; }
.margin-b-40 { margin-bottom: 4rem; }
.margin-b-45 { margin-bottom: 4.5rem; }
.margin-b-50 { margin-bottom: 5rem; }
.margin-b-65 { margin-bottom: 6.5rem; }

.padding-b-0 { padding-bottom: 0; }
.padding-t-0 { padding-top: 0; }

.underline { text-decoration: underline; }

.block { display: block; }
.inline-block { display: inline-block; }

.font-size-14 { font-size: 1.4rem; }
.font-size-19 { font-size: 1.9rem; }

.font-semibold { font-family: $semibold-font; }

.flex-column { display: flex; flex-direction: column; }
.flex-grow { flex-grow: 1; }
.flex-centre { display: flex; align-items: center; justify-content: center; }
.flex-column-justify-centre { justify-content: center; }

.max-width-340 { max-width: 34rem; }
.max-width-form { max-width: 34rem; width: 100%; display: flex; flex-direction: column; }
.centre { margin-left: auto; margin-right: auto; }

.colour-text { color: $text-colour; }
.colour-recessed { color: #666; }

.visuallyhidden { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px;  margin: -1px; padding: 0; border: 0; }

.text-align-centre { text-align: center; }
.text-align-l { text-align: left; }
.text-align-r { text-align: right; }

.sticky { position: sticky; top: 0; z-index:2; }
.sticky--with-white-fade { background: #F1EEF3; margin: 0 0 6rem;
  &:after { content: ''; display: block; position: absolute; left: 0; width: 100%; top: 100%; background: linear-gradient(180deg, rgba(241,238,243,1) 0%, rgba(241,238,243,0) 100%); height: 6rem; }
}

.disabled { opacity:.25; pointer-events:none;}

@media only screen and (max-height: 600px) {
  #today { justify-content: flex-start; }
}

[v-cloak] { display: none !important; }

.app-navigation { display: none !important; }
.app-navigation--visible { display: flex !important; }

.privacy-policy-content {
  ul { padding: 0 0 2rem 3rem; }
  ul li { list-style: circle; line-height: 1.5; margin: 0 0 .5rem; }
  a { word-wrap: break-word; text-decoration: underline; }
}