.app-navigation { position: absolute; left: 0; right: 0; bottom: 0; background: $default_primary; display: flex; color: #fff; height: $navigation-height; font-family: $semibold-font; font-size: 1.1rem; line-height: 1; z-index: 10; box-shadow: 0px -2px 24px rgba(0, 0, 0, 0.1);

  &__link { flex: 1; color: inherit; display: flex; align-items: center; justify-content: center; flex-direction: column; opacity: .5; transition: .3s ease; padding: .7rem 0 0; outline: none;
    &.on { opacity: 1; }
  }

  &__icon { fill: currentColor; margin: 0 0 .5rem; }

  &__indicator { position: absolute; width: 20%; left: 0; top: .8rem; height: .4rem; pointer-events: none; transition: left .3s ease;
    &::after { position: absolute; content: ''; display: block; background: #fff; height: .4rem; width: .4rem; border-radius: .2rem; left: 50%; top: 50%; transform: translate(-50%,-50%); }

    .app-navigation--1 & { left: 0; }
    .app-navigation--2 & { left: 20%; }
    .app-navigation--3 & { left: 40%; }
    .app-navigation--4 & { left: 60%; }
    .app-navigation--5 & { left: 80%; }

  }

}

.app-navigation {
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}