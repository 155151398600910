

.questions { position: relative; }

.question { text-align: center; overflow-x: hidden; overflow-y: auto; width: 100%; transition: left .3s ease; display: flex; flex-direction: column; -webkit-overflow-scrolling: touch;
  .survey-page-question--current & { left: 0; }
  &.answered { left: -100%; }
}
.question-holder { max-width: 28rem; margin: 0 auto; }

.question__answer-list { margin: 0 -2rem; 
  li { margin: 0 0 .5rem; }
}


.finish-button { transform: translateX(4rem); transition: transform 1s ease; 
  .survey-page--current & { animation: slideFromRight .6s ease .2s forwards;  }
}

@keyframes slideFromRight {
  from {transform: translateX(4rem);}
  to {transform: translateX(0rem); }
}

.question__answer { font-family: $semibold-font; font-size: 1.8rem; padding: .9rem 1.2rem .7rem; line-height: 1.4; width: 100%; }

@media only screen and (max-width: 374px) {
  .question__answer { font-size: 1.6rem; }
}

.center { width: 50vw; margin: 0 auto; }
.mood-slide { padding: 0; transform: scale(.6,.6); transform-origin: center center; transition: all .25s ease; opacity: .3; outline: none;
  &.slick-current { transform: scale(1,1); opacity: 1; }
}
.mood-slide__content {  padding: 8vh 0 12vh;
  img { margin: 0 auto 2rem; width: 35vw; height: 35vw; }
  p { font-family: $bold-font; font-size: 1.6rem; margin: 0; }
}
.slick-list { overflow: visible !important; }

@media only screen and (min-width: 601px) and (min-height: 670px) {
  .mood-slide__content {  padding: 4rem 0; 
    img { width: 17rem; height: 17rem; }
  }
  .center { width: 100%; }
}

.mood-question-character { margin: 0 0 2rem; transform: translateX(4rem);
  .survey-page--current & { animation: slideFromRight .6s ease 0s forwards; }
}
.mood-question__title { font-family: $regular-font; font-size: 1.6rem; line-height: 2.2rem; margin: 0 auto 1rem; max-width: 28rem; }
.mood-question__word { font-family: $bold-font; font-size: 3.6rem; margin: 0 0 2rem; }
.mood-question__description { font-size: 1.2rem; font-family: $bold-font; text-transform: uppercase; }
.impact__title { font-family: $semibold-font; font-size: 2.4rem; line-height: 1.2; margin: -10rem auto 1rem; max-width: 24rem; }


.survey-section { text-align: center; width: 100%; transition: left .3s ease; display: flex; flex-direction: column; -webkit-overflow-scrolling: touch;
  &--current { left: 0; }
  &.answered { left: -100%; }
}
.survey-page { text-align: center; transition: left .3s ease; display: flex; flex-direction: column; -webkit-overflow-scrolling: touch; padding: 2rem 0 10rem;
  &--current { left: 0; }
  &.answered { left: -100%; }
}
.survey-page-question { display: none; position: absolute; 
  &--current { display: block; position: static; }
}

@media only screen and (min-width: 601px) and (min-height: 670px) {
  .questions { height: 56rem; }
  .modal-mood-question--survey .question__answer-list { padding: 0 0 4rem; }
}

@media only screen and (max-height: 640px) {
.questions { height: calc(100vh - 4rem); }
.mood-question__word { margin: 0; }
.mood-question__description { margin: 1rem 0 0; }
}

.survey-page-question-rating-mood { padding-top: 5rem; }



.survey-checkbox { position: relative; margin: 0 0 3rem; }
.survey-checkbox__checkbox { position: absolute; opacity: 0; }
.survey-checkbox__label { font-family: $semibold-font; font-size: 1.8rem; line-height: 1.4; padding: 0 0 0 4.5rem; display: block; text-align: left;
  &::before { display: block; content: ''; position: absolute; width: 3rem; height: 3rem; border: .1rem solid #ccc; background: #fff; left: 0; top: 0; border-radius: .2rem; }
}
.survey-checkbox__checkbox:checked {
  + .survey-checkbox__label { 
    &::after { display: block; content: ''; position: absolute; width: 3rem; height: 3rem; background: #0AC267 url(../images/check.svg) no-repeat center center; left: 0; top: 0; color: #fff; border-radius: .2rem; }
  }  
}



.survey-page-question-checkboxgroup { padding-bottom: 40px;
  .question__answer-list { padding-bottom: 0; }
}

@media only screen and (max-width: 374px) {
  .survey-checkbox__label { font-size: 1.6rem; }
  .survey-page-question-checkboxgroup { padding-bottom: 80px;
    .question__answer-list { padding-bottom: 0; }
  }
}

.app { 
  .survey-holder,
  .survey-section,
  .survey-page { position: absolute; top: 0; bottom: 0; width: 100%; }
  .survey-holder { top: 70px; }
  .question-holder { max-width: 50rem; width: 90%; 
    .question__answer-list { margin: 0; }
  }
  .survey-section,
  .survey-page { display: none;
    &--current {display: block}
  }
  //.survey-page { display: none; }
  //.survey-page--current { display: block; }
  //.survey-page-question--current {  position: absolute; left: 0; top: 0; right: 0; bottom: 0; max-width: none; overflow: auto; }
  .survey-page-question--current { overflow: auto;}
 }