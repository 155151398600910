.screen { position: fixed; left: -100%; top: 0; width: 100%; bottom: $navigation-height; -webkit-overflow-scrolling: touch; padding: 4rem; z-index: 1; opacity: 0; pointer-events: none; background-color: #F1EEF3; overflow: auto; background-repeat: no-repeat; background-attachment: local; overflow-x: hidden;
  &--no-scroll { overflow: hidden; }
  &--full-screen { bottom: 0; }
  &--with-buttons { padding-top: 7.5rem; }
  &--tint { background: $default_page-background-tint; }
  &--white { background: #fff; }
  &--padding-20 { padding: 2rem; }
  &--with-section-nav { padding-top: 5rem; }
  &--centre-content { display: flex; align-items: center; justify-content: center; }
  &--account-form { padding-top: 5.5rem; }
  &--current { opacity: 1; pointer-events:initial; z-index: 2; left: 0;
    &.screen--over-nav { z-index: 11; }
  }
  
}
.online-survey-holder {
  .online-survey { padding: 4rem 1rem; height: 100%;
    @media only screen and (min-width: 601px) and (min-height: 670px) {
      padding: 4rem 4rem;
    }
    &.screen--current { display: block; }
  }
  .screen { position: absolute; left: 0; top: 0; bottom: 0; display: none; padding: 4rem 1rem 0;
    
    @media only screen and (min-width: 601px) and (min-height: 670px) {
      padding: 4rem 4rem 0;
    }
    &.screen--current { display: flex; }
    &#survey-annual-intro { padding-bottom: 4rem; }
  }
  .survey { position: absolute; left: 0; top: 0; bottom: 0; display: none; padding: 4rem 1rem 0; display: none; overflow: auto;
    @media only screen and (min-width: 601px) and (min-height: 670px) {
      padding: 4rem 4rem 0;
    }
    &.screen--current { display: flex; flex-direction: column; }
    .question-holder { max-width: unset !important; padding: 2rem 0; background: #fff; }
    .questions { height: auto; }
    .survey-section,
    .survey-page { position: relative; left: 0; top: 0; padding: 0}
    .survey-page { display: none; padding: 0 1rem;
      &.survey-page--current { display: flex; }
    }
    .survey-section { display: none;
      &.survey-section--current { display: block; }
    }
    .finish-button, .skip-button { margin-top: 0; }
    .question__answer-list { padding: 0; }
    .question-holder h2 { min-height: unset;}
  }
  .progress--question { margin: 0; }
  .survey-page-question  {
    h2 { position: sticky; top: 0; background: #fff; padding: 2rem 0; z-index: 1; border-bottom: 1px solid #ededed; }
    &.question-holder { padding: 0 0 2rem; }
  }

  .background-question { background: none; }
  .survey-page h2 { font-size: 1.6rem; }
  .question__answer { font-size: 1.6rem; border-radius: .5rem !important; }
  .skip-button { font-size: 1.6rem; }
  .survey-checkbox__label { font-size: 1.6rem; font-family: poppinsregular,sans-serif; padding-top: .6rem; margin: 0 0 2rem; }
  b, strong { font-family: poppinssemibold,sans-serif; }
  .button--block { width: auto; padding: 1rem 2.5rem; }

  .question-intro { display: flex; flex-direction: column; }

  .standard-input { font-size: 1.6rem; }

  .tooltip-text { visibility: hidden; position: absolute; z-index: 100; min-width: 16rem; color: white; font-size: 1.3rem; background-color: #212121; border-radius: 5px; padding: 10px 15px 10px 15px; top: calc(100% + 1rem); left: 50%; transform: translateX(-50%); font-family: poppinsregular,sans-serif;  line-height: 1.4;

    &::after { 
      display: block; 
      content: ''; 
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      left: 50%;
      bottom: 100%;     
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #212121 transparent;
      transform: translateX(-50%);
    }

  }
  .hover-text { position: relative; display: inline-block; text-align: center; border-bottom: dotted; padding: 0 .3rem; }
  .hover-text:hover { z-index: 99;
      .tooltip-text { visibility: visible; }
  }
}


.hide-animation { position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; padding: 2rem; }

.survey { left: -100%; width: 100%; top: 100%; height: 100%;  z-index: 100;  padding: 4rem 0 0; background-repeat: no-repeat; background-attachment: local;
  &.screen--current { top: 0; left: 0; }
}

#survey-diary.survey,
#support.screen { background-color: #fff; }