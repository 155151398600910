.survey-question-intro { display: flex; flex-direction: column; padding: 9rem 4rem 2rem; }

.question-intro { margin: 0 auto; 
  &__number { font-size: 2rem; font-family: $semibold-font; color: #999; line-height: 1.2; }
  &__text { font-size: 2rem; font-family: $regular-font; line-height: 1.2; }
}

.question-complete { margin: 0 auto; 
  &__title { font-size: 3.6rem; font-family: $semibold-font; color: #999; line-height: 1.2; }
  &__text { font-size: 2rem; font-family: $regular-font; line-height: 1.2; }
}

.consent { position: absolute; left: 0; top: 0; right: 0; background: #ededed; padding: 4rem; z-index:10; }