.modal-background { position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: $modal-cover-background; z-index: 99; opacity: 0; pointer-events:none; 
  &.modal-background--visible { opacity: 1; pointer-events:initial; }
}

.modal-blanker {  background: #FFF; z-index: 98; position: absolute; width: 100vw; height: 100vh; text-align: center; line-height: 100vh; transition: opacity 0.5s; -webkit-transition: opacity 0.5s; opacity: 0; pointer-events: none; }
.modal-blanker.modal-blanker--visible { opacity: 1; }

.modals { max-height: 100%; }

.modal { position: fixed; left: -550%; top: 50%; background: #fff; box-shadow: $modal-shadow; border-radius: $modal-border-radius; z-index: 100; transform: translate(-50%,-50%); padding: 6.5rem 2rem 3rem; width: 31rem; opacity: 0; pointer-events: none; background-color: #F1EEF3; background-attachment: local; background-repeat: no-repeat; overflow: hidden;
  &.modal--visible { opacity: 1; pointer-events: auto; left: 50%; }
  &--full-screen { left: 0; width: 100%; top: 0; height: 100%; transform: none; padding: 4rem; border-radius: 0; overflow-y: auto; opacity: 0; transition: opacity .2s ease; 
    &.modal--visible { opacity: 1; pointer-events: auto; left: 0; }
  }
  &-survey { background: $default-primary; color: #fff; }
  
}

.modal-survey__character { margin: 0 -4rem; padding: 0 0 0 3.5rem;
  .character__blob { left: 0; top: auto; bottom: 50%; transform: translateY(50%); width: 50%;}
}

.modal-mood-question { padding: 0; 
  &--survey {
    .progress { background: #ededed; }
    .question__answer-list { margin: 0;
      li { margin: 0 0 1.5rem; }
    }
    .question-holder { width: 100%; max-width: 34rem; padding: 0 2rem;
      h2 { min-height: 8.4rem; }
      .shallow-heading { min-height: 5.6rem; }
    }
    .question__answer { border: .1rem solid $default_primary; border-radius: 2.6rem; padding: 1.7rem 1.2rem 1.5rem; background: #fff; 
      &:hover { background: #ededed; }
    }
  }
}
.question-pre-title { font-size: 1.6rem; margin: 0 0 .3rem; }