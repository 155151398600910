.add-a-diary-entry { position: relative; height: 33rem; text-align: center; margin: 0 -4rem;
  &__title { font-size: 1.8rem; font-family: $semibold-font; line-height: 1.4; max-width: 31rem; margin: 4rem auto 0; }
  &__pleasant-blob { position: absolute; right: 46%; top: 8rem; }
  &__unpleasant-blob { position: absolute; left: 46%; top: 16rem; }
  &__link { position: absolute; display: block; text-align: center; width: 15rem; top: 15rem; line-height: 1.2; font-size: 1.8rem; font-family: $semibold-font; margin: 0 1rem;
    img { margin: 0 0 1rem; pointer-events: none; max-width: 11rem; }
    &--pleasant { right: 50%; }
    &--unpleasant { left: 50%; }
  }
}

.diary-answer-list { display: flex; flex-wrap: wrap; margin: 0 -4rem;
  li { width: 50%; 
    img { margin: 0; width: 11rem; }
  }
}

@media only screen and (min-height: 670px) and (min-width: 601px) {
  .add-a-diary-entry { 
    &__link { 
      img { max-width: 13rem; }
    }
  }
  .diary-answer-list { display: flex; flex-wrap: wrap; margin: 0 -13rem;
    li { width: 33.333%; 
      img { width: 16rem; margin: 0 0 1rem; }
    }
  }
}