.diary-number-of-entries { height: 20rem; position: relative; padding: 7.5rem 0 0 45%; text-align: left; margin: 0 0 3rem;
  &__blob { position: absolute; right: 55%; bottom: 3rem; }
  &__character { position: absolute; right: 60%; bottom: 0; }
  &__number { font-size: 7.2rem; font-family: $bold-font; line-height: 1; }
  &__anchor { text-transform: uppercase; font-family: $bold-font; font-size: 1rem; letter-spacing: .2rem; position: relative; margin: 0; line-height: 1;
    &::after { content: ''; display: block; top: 100%; height: .2rem; width: 5rem; background: $default_primary; margin: 2rem 0; }
  }
}

.month-stats { text-align: center; 
  .button { transform: translateY(-4rem); }
}