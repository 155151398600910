.eek { position: fixed; left: 0; top: 0; right: 0; bottom: 0; display: none; align-items: center; justify-content: center; z-index: 9999; background: #fff url(/myjourne/images/eek-background.svg) no-repeat center top; pointer-events: none;
   .show-eek & {  display: flex; }
}
.eek__image { position: absolute; top: 0; left: 50%; transform: translateX(-50%);}

.rotate-message { display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%) rotate(180deg); font-size: 1.8rem; text-align: center; color: #333; font-family: $bold-font; }

#smoke {
	position: absolute;
	z-index: 3;
	width: 1px; height: 160px;
	left: 50%; top: -5rem;
}

/* smoke balls */

#smoke span { 
  display: block;
	position: absolute;
	top: -5rem; left: 50%; margin-left:-2rem;
	height: 0px; width: 0px;
	border: 5rem solid #4b4b4b;
	border-radius: 5rem;
	left: -2.5rem; opacity: 0;
	transform: scale(0.2);
}

/* Smoke animation */

@keyframes smokeL1 {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, 5px) }
	100% { opacity: 0; transform: scale(1) translate(20px, 130px) }
}

@keyframes smokeR1 {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0,5px) }
	100% { opacity: 0; transform: scale(1) translate(-20px, 130px) }
}

@keyframes smokeL2 {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, 5px) }
	100% { opacity: 0; transform: scale(1) translate(40px, 100px) }
}

@keyframes smokeR2 {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, 5px) }
	100% { opacity: 0; transform: scale(1) translate(-40px, 100px) }
}

#smoke .s0 { animation: smokeL1 10s 0s infinite }
#smoke .s1 { animation: smokeR1 10s 0.5s infinite }
#smoke .s2 { animation: smokeL2 10s 1s infinite }
#smoke .s3 { animation: smokeR2 10s 1.5s infinite }
#smoke .s4 { animation: smokeL1 10s 2s infinite }
#smoke .s5 { animation: smokeR1 10s 2.5s infinite }
#smoke .s6 { animation: smokeL2 10s 3s infinite }
#smoke .s7 { animation: smokeR2 10s 3.5s infinite }
#smoke .s8 { animation: smokeL1 10s 4s infinite }
#smoke .s9 { animation: smokeR1 10s 4.5s infinite }
#smoke .s10 { animation: smokeL2 10s 5s infinite }
#smoke .s11 { animation: smokeR2 10s 5.5s infinite }
#smoke .s12 { animation: smokeL1 10s 6s infinite }
#smoke .s13 { animation: smokeR1 10s 6.5s infinite }
#smoke .s14 { animation: smokeL2 10s 7s infinite }
#smoke .s15 { animation: smokeR2 10s 7.5s infinite }
#smoke .s16 { animation: smokeL1 10s 8s infinite }
#smoke .s17 { animation: smokeR1 10s 8.5s infinite }
#smoke .s18 { animation: smokeL2 10s 9s infinite }
#smoke .s19 { animation: smokeR2 10s 9.5s infinite }