.charts-list { display: flex; flex-wrap: wrap;
  &__chart { width: 50%; border-bottom: .1rem solid #ccc; padding: 2rem; position: relative; display: flex; flex-direction: column;
    &:nth-child(2n)::after { content: ''; display: block; position: absolute; left: 0; top: 0; height: 100%; width: .1rem; background: #ccc; }
  }
}

.charts-list__chart__title { position: relative; font-size: 1.3rem; font-family: $medium-font; margin: 0 0 2rem; text-align: left; padding-right: 3.2rem; line-height: 1.3; flex-grow: 1;
  span { position: absolute; right: 0; top: 0; color:  $default-count; }
}

@media only screen and (max-width: 364px) {
  .charts-list__chart { padding: 1rem}
}