.background-trial-closed { background-image: url(../images/background-closed.png); background-size: 60rem 67rem; padding: 0; 
  .character__character { margin-left: -2rem; }
}
.background-trial-feedback { background-image: url(../images/background-closed.png); background-size: 60rem 67rem; background-color: #fff; background-position: center 5rem; padding: 0; 
  .character__character { margin-left: -2rem; }
}
.trial-closed-title { font-size: 1.8rem; margin: 0 0 1rem; text-align: center; }
.trial-closed-intro { font-size: 1.4rem; color: #333; text-align: center; margin: 0; }

.trial-closed-footer { background: #E7E1EB; }
.trial-closed-text { text-align: center; margin: 0 0 1.5rem;
  h2 { font-size: 1.8rem; margin: 0 0 1rem; }
  p { color: #333; font-size: 1.4rem; margin: 0; }
  a { color: inherit; text-decoration: underline; }
}
.trial-closed-top { padding: 4rem 2rem 2rem; 
  .character { margin-bottom: 1rem; }
}
.trial-closed-feedback-top { padding: 8rem 2rem 2rem; text-align: center;
  .character { margin-bottom: 1rem; }
}
.trial-closed-footer { padding: 2rem 2rem 1rem; text-align: center; }
.trial-closed-footer__text { font-size: 14px; color: #333; }

.trial-closed-contact { text-align: center; font-weight: 600; color: #333; margin: 0 0 2.5rem;
  span { color: $default-primary; word-break: break-all; }
}
.trial-closed-buttons { text-align: center;
  button { margin: 0 .5rem 1rem; min-width: none; padding-left: 1.5rem; padding-right: 1.5rem; font-size: 1.3rem; }
}

.rating-stars { text-align:center; }
.rating-star { width: 44px; height: 42px; display: inline-block; margin:2px; }
.rating-star.set { svg { fill: #6f4e85;} }