.modal-diary-entry { position: relative; 
  &::after { display: block; content: ''; height: 6rem; background: linear-gradient(0deg, rgba(241,238,243,1) 20%, rgba(241,238,243,0) 100%); position: absolute; bottom: 0; left: 0; right: 0; }
}

.review-diary-entry { max-width: 40rem; margin: 3rem auto 0; width: 90%; height: calc(100% - 20rem); padding: 0 0 4rem; position: relative; 
  &__title { font-size: 1.8rem; line-height: 1.5; margin: 0 0 2rem; }
  &--no-max-height { height: auto; }
}

.diary-entry-impact { margin: 4rem 0 10rem; 
  &__title { font-size: 1.4rem; margin: 0 0 1.5rem; }
  &__chart { height: 2rem; background: rgba(0,0,0,.05); border-radius: .6rem; position: relative; margin: 0 0 1rem; }
  &__chart-bar { height: 2rem; background: $default_button; border-radius: .6rem; width: 50%; position: absolute; left: 0; top: 0; }
  &__description { font-size: 1.2rem; font-family: $bold-font; text-transform: uppercase; text-align: center; }
}

@media only screen and (min-width: 620px) {
   .review-diary-entry { width: 48rem; padding: 0 4rem 4rem; }
}

.diary-empty { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); }