.character { position: relative; text-align: center; }

.character__blob { position: absolute; left: 50%; top: 0; transform: translateX(-50%); width: 28rem; max-width: none; }
.character__character { position: relative; z-index: 1; }

.mood-question-character { height: 17rem; position: relative; text-align: center; margin: 0 0 2rem; }
.mood-question-character__blob { position: absolute; left: 80%; top: 0; transform: translateX(-50%); width: 28rem; max-width: none; transition: left .6s ease; 
  .survey-page--current & { left: 50%; }
  .answered & { left: 20%; }
  &--inspired { width: 16rem; }
}

.mood-question-character__character { position: absolute; z-index: 1; bottom: 0; left: 80%; transform: translateX(-50%); transition: left .8s ease; 
  .survey-page--current & { left: 50%; }
  .answered & { left: 20%; }
  .mood-question--active & { bottom: -3rem; }
}

.complete-character { height: 30rem; position: relative; text-align: center; margin: 0 0 2rem; }
.complete-character__title { position: absolute; z-index: 1; bottom: 15rem; left: 50%; transform: translateX(-50%); }
.complete-character__blob { position: absolute; left: -20%; top: -50%; width: 140%; background: #9BBAD8; max-width: none; height: 140%; border-radius: 50%; overflow: hidden; z-index: 0; }

.diary-entry-character { height: 17rem; position: relative; text-align: center; margin: 0 0 2rem; 
  &__blob { position: absolute; left: 50%; top: 0; transform: translateX(-50%); width: 28rem; max-width: none; }
  &__character { position: absolute; z-index: 1; bottom: 0; left: 55%; transform: translateX(-50%); }
}